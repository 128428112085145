@mixin fullWidth {
    width: 100%;
    float: left;
}

.SinginFormPage {
    @include fullWidth();
    position: relative;

    .SinginFormLeft {
        @include fullWidth();

        .SinginFormBgImg {
            @include fullWidth();
        }

        .SinginFormCarouselContainer {
            @include fullWidth();
            padding: 0 80px;
            position: relative;
            top: -50px;

            .SinginFormCarouselTitle {
                @include fullWidth();
                font-weight: 700;
                font-size: 36px;
                line-height: 39px;
                color: #002347;
            }
        }
    }

    .SinginFormRight {
        @include fullWidth();
        padding: 90px 30px;

        .SinginFormLogo {
            width: 90px;
            height: 90px;
        }

        .SinginFormTitleContainer {
            width: 100%;
            padding: 40px 0;
            display: flex;
            flex-direction: column;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            color: #313131;

            .SinginFormTitle {
                font-weight: 600;
                font-size: 34px;
                line-height: 40px;
                color: #223385;
                margin-bottom: 12px;
            }
        }

        .SinginFormPageContainer {
            @include fullWidth();

            .form-control {
                width: 100%;
                margin-top: 27px;
                margin-bottom: 30px;

                .MuiFormLabel-root {
                    margin-top: -46px;
                    margin-left: -14px;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 19px;
                    color: #313131;
                }

                .MuiTextField-root {
                    .MuiInputBase-root {
                        .MuiInputBase-input {
                            padding: 18px 14px;
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 14px;
                            color: #555555;
                        }
                    }

                    .MuiOutlinedInput-notchedOutline {
                        border-color: #D1D1D1;
                    }
                }
            }

            .SinginFormBtn {
                @include fullWidth();
                background: #FFC100;
                border-radius: 5px;
                font-weight: 500;
                font-size: 16px;
                line-height: 19px;
                color: #223385;
                height: 54px;
                padding: 10px 16px;
                box-shadow: none;
                margin-top: 20px;
                margin-bottom: 50px;
            }

            .SinginFormLoginBtn {
                @include fullWidth();
                height: 54px;
                padding: 10px 16px;
                border-color: #223385;
                font-weight: 500;
                font-size: 16px;
                line-height: 19px;
                color: #223385;
            }
        }
    }

    .SignInGeoTop {
        position: absolute;
        top: 0;
        right: 0;
        height: 80px;
    }

    .SignInGeoBottom {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 115px;
    }
}

.signInCarousel {
    @include fullWidth();

    .item {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding: 30px 0;

        .signInCarouselSet {
            width: 50%;
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 10px 0;

            .signInCarouselSetImg {
                width: 48px;
                height: 48px;
                border-radius: 100%;
            }

            .signInCarouselSetText {
                font-size: 16px;
                line-height: 19px;
                text-align: center;
                color: #2B3E97;
                margin-left: 20px;
            }
        }
    }

    .owl-dots {
        display: flex;
        align-items: center;
        justify-content: center;

        .owl-dot {


            span {
                display: block;
                width: 20px;
                height: 10px;
                margin-right: 5px;
                background: #E1EFFF;
                border-radius: 5px;
                transition: all ease .3s;
            }

            &.active {
                span {
                    width: 40px;
                    background: #223385;
                    transition: all ease .3s;
                }
            }
        }
    }
}


.CompanyRegistrationPage {
    @include fullWidth();
    margin-top: -10px;

    .RegistrationBannerLeft {
        @include fullWidth();
        padding: 50px 80px;
        padding-bottom: 30px;
        @media (max-width: 767px) {
            padding: 15px 15px;
          }

        .RegistrationBannerTitle {
            @include fullWidth();
            font-weight: 800;
            font-size: 54px;
            line-height: 50px;
            color: #223385;
            padding-bottom: 28px;
            @media (max-width: 767px) {
                font-size: 30px;
                line-height: 30px;
                padding-bottom: 15px;
              }
        }

        .RegistrationCheckListContainer {
            @include fullWidth();

            .RegistrationCheckListSet {
                width: 100%;
                padding: 12px 0;
                display: flex;
                align-items: center;
                flex-direction: row;
                font-weight: 400;
                font-size: 14px;
                line-height: 16px;
                color: #555555;

                .RegistrationCheckListSetIcon {
                    width: 20px;
                    height: 20px;
                    margin-right: 12px;
                }
            }
        }
    }

    .RegistrationBannerRight {
        @include fullWidth();
        position: relative;
        z-index: 1;

        svg {
            @include fullWidth();
            @media (max-width: 767px) {
                width: 100%;
                height: 100%;
            }
        }
    }

    .RegistrationWizardContainer {
        @include fullWidth();
        padding: 0 80px;
        position: relative;
        margin-top: -107px;
        @media (max-width: 767px) {
            padding: 0 15px;
        }

        .RegistrationWizardTitle {
            @include fullWidth();
            padding: 35px 0;
            font-weight: 600;
            font-size: 24px;
            line-height: 28px;
            color: #002347;
        }
    }
}

.registrationWizard {
    @include fullWidth();
    background-color: #fff;

    .WizardStepper {
        @include fullWidth();
        background: #F4FFFB;
        border-radius: 10px;
        padding: 30px 30px;
        margin-top: -10px;
        @media (max-width: 767px) {
            padding: 15px 15px;
            margin-top: 30px;
        }

        .MuiStepper-root {
            width: 60%;
            @media (max-width: 767px) {
                width: 100%;
            }

            .MuiStep-horizontal {
                width: 43.33%;
                display: flex;
                position: relative;

                .MuiStepLabel-horizontal {
                    display: flex;
                    flex-direction: column;

                    .css-1vyamtt-MuiStepLabel-labelContainer {
                        text-align: center;
                        margin-top: 18px;

                        .MuiStepLabel-label {
                            font-size: 14px;
                            line-height: 16px;
                            text-align: center;
                            color: #AEC4FF;
                        }
                    }

                    .MuiStepLabel-iconContainer {
                        padding-right: 0;

                        &:after {
                            content: "";
                            position: absolute;
                            left: 60px;
                            right: -35px;
                            top: 22px;
                            height: 5px;
                            background-color: #D9E3FE;
                        }

                        .WizardStepperIcon {
                            border-radius: 50%;
                            width: 48px;
                            height: 48px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            background-color: #D9E3FE;
                            padding-top: 4px;
                            position: relative;
                            z-index: 2;
                        }

                        &.Mui-completed {
                            &:after {
                                background-color: #32A87D;
                            }
                        }

                        &.Mui-active,
                        &.Mui-completed {
                            .WizardStepperIcon {
                                background-color: #32A87D;
                            }

                            &+.MuiStepLabel-labelContainer {
                                .MuiStepLabel-label {
                                    color: #32A87D;
                                }
                            }
                        }

                    }
                }

                &:last-child {
                    width: auto;

                    .MuiStepLabel-iconContainer {
                        &:after {
                            display: none;
                        }
                    }
                }
            }
        }

        .MuiStepConnector-root {
            margin-top: -45px;
            display: none;
        }
    }
}

.WizardFormContainer {
    @include fullWidth();
    padding: 30px 30px;
    margin: 30px 0;
    border: 1px solid #E2E2E2;

    .title {
        @include fullWidth();
        font-weight: 600;
        font-size: 18px;
        line-height: 21px;
        color: #223385;
    }

    .form-control,
    .MuiFormControl-root {
        @include fullWidth();

        .MuiInputBase-input {
            font-size: 14px;
            line-height: 16px;
            color: #000;
        }

        .MuiInputLabel-root {
            font-weight: 400;
            font-size: 14px;
            line-height: 15px;
            text-align: center;
            color: #555555;
            padding-top: 5px;

            &.Mui-focused {
                color: #555555;
            }
        }

        .MuiOutlinedInput-notchedOutline {
            border-color: #E2E2E2 !important;
        }

        .MuiOutlinedInput-root {
            &:hover {
                .MuiOutlinedInput-notchedOutline {
                    border-color: #E2E2E2;
                }
            }
        }

        &.fileAttachmentInput {
            .MuiInputBase-input {
                &::file-selector-button {
                    display: none;
                }

            }

            .MuiOutlinedInput-root {
                padding-right: 0;
                position: relative;

                .MuiInputBase-input {
                    position: relative;
                    z-index: 2;
                    cursor: pointer;
                }

                .MuiInputAdornment-positionEnd {
                    position: absolute;
                    right: 15px;
                    height: 100%;
                    transform: rotate(45deg);
                }
            }
        }
    }

    .WizardNextStep {
        @include fullWidth();

        .WizardNextBtn {
            float: right;
            background: #FFC100;
            border-radius: 5px;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: #223385;
            height: 44px;
            padding: 7px 25px;
            box-shadow: none;
            text-transform: none;

            .MuiSvgIcon-fontSizeMedium {
                margin-left: 10px;
            }
        }

        .WizardBackBtn {
            float: left;
            border-radius: 5px;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: #223385;
            border-color: #223385;
            height: 44px;
            padding: 7px 25px;
            text-transform: none;

            .MuiSvgIcon-root {
                margin-right: 10px;
                transform: rotate(180deg);
            }
        }
    }

    textarea {
        border: solid 1px #E2E2E2 !important;
        padding: 16.5px 14px;

        &:focus-visible {
            outline: none !important;
        }
    }
}

.addMoreItemBtn {
    &.MuiGrid-item {
        &.MuiGrid-root {
            padding-top: 15px;

            .MuiButtonBase-root {
                float: right;
                font-weight: 500;
                font-size: 14px;
                line-height: 16px;
                text-align: center;
                color: #223385;
                text-transform: none;
            }
        }
    }
}

.WizardSubscriptionPlan {
    @include fullWidth();
    padding: 30px 0;

    .title {
        @include fullWidth();
        font-weight: 600;
        font-size: 18px;
        line-height: 21px;
        color: #223385;
    }

    .WizardSubscriptionContainer {
        @include fullWidth();
        padding: 20px 20px;
        background: #FAF8FF;
        border-radius: 10px;
        margin: 30px 0;

        .WizardSubscriptionTitle {
            @include fullWidth();
            font-weight: 400;
            font-size: 20px;
            line-height: 23px;
            color: #00702D;

            strong {
                font-size: 22px;
                font-weight: 800;
            }
        }

        .WizardSubscriptionCard {
            @include fullWidth();
            height: 100%;
            padding: 20px 20px;
            background: #FFFFFF;
            box-shadow: -3px -2px 6px rgba(34, 51, 133, 0.05), 3px 4px 8px rgba(34, 51, 133, 0.05);
            border-radius: 5px;

            .SubscriptionCardTitle {
                @include fullWidth();
                font-weight: 500;
                font-size: 16px;
                line-height: 19px;
                color: #223385;
            }

            .SubscriptionCardContent {
                @include fullWidth();
                padding-top: 15px;
                font-weight: 400;
                font-size: 14px;
                line-height: 16px;
                color: #555555;
            }
        }

        .SubscriptionWizardBtn {
            width: 100%;
            margin-top: 30px;
            display: flex;
            align-items: center;
            justify-content: center;

            .SubscriptionBtn {
                width: 50%;
                background: #FFC100;
                border-radius: 5px;
                font-weight: 500;
                font-size: 16px;
                line-height: 19px;
                color: #223385;
                height: 44px;
                padding: 7px 25px;
                box-shadow: none;
                text-transform: none;
                @media (max-width: 767px) {
                    width: 100%;
                }

                .MuiSvgIcon-fontSizeMedium {
                    margin-left: 10px;
                }
            }
        }
    }
}

// Dashboard Style Start
.dashboardPageContainer {
    @include fullWidth();
    padding: 30px 40px;
    padding-right: 80px;

    .mainTitle {
        @include fullWidth();
        font-weight: 700;
        font-size: 28px;
        line-height: 33px;
        color: #223385;
        span {
            color: #4A5486;
            font-size: 26px;
            font-weight: 500;
        }
    }

    .postedJobsCard {
        @include fullWidth();
        padding: 40px 40px;
        background: #F1F4FF;
        border-radius: 10px;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        color: #313131;
        margin-top: 30px;

        strong {
            font-weight: 600;
            font-size: 22px;
            line-height: 26px;
            color: #223385;
            margin-left: 20px;
        }
    }
}

.dashboardCardTitle {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    color: #002347;
    padding: 25px 0;

    .viewAllLink {
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        text-decoration-line: underline;
        color: #0085FF;
    }
}

.subscriptionvalidityCard {
    @include fullWidth();
    background-color: #FFFFFF;
    box-shadow: -3px -2px 6px rgba(34, 51, 133, 0.05), 3px 4px 8px rgba(34, 51, 133, 0.05);
    border-radius: 5px;

    .cardHeader {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 20px 24px;
        background-color: #FBFAFF;

        .ScardHeaderImg {
            width: 80px;
            height: 80px;
            margin-right: 30px;
        }

        .cardHeaderContent {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            font-size: 16px;
            line-height: 19px;
            color: #5461A3;
            font-weight: 500;

            strong {
                font-weight: 600;
                font-size: 26px;
                line-height: 30px;
                text-align: center;
                color: #223385;
                margin-top: 12px;
            }
        }
    }

    .cardBody {
        @include fullWidth();
        padding: 20px 20px;
        padding-bottom: 0;

        .cardBodyTitle {
            @include fullWidth();
            font-size: 16px;
            line-height: 19px;
            color: #002347;
            font-weight: 600;
            margin-bottom: 15px;
        }

        .cardPlanSet {
            padding: 7.5px 0;
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: flex-start;

            img {
                width: 14px;
                height: 14px;
            }

            .cardPlanContent {
                display: flex;
                flex-direction: column;
                font-weight: 400;
                font-size: 14px;
                line-height: 16px;
                color: #555555;
                margin-left: 12px;

                .cardPlanContentTitle {
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 16px;
                    color: #313131;
                    margin-bottom: 10px;
                }
            }
        }
    }
}

// Dashboard Style End


// Job style start
.activeJobGridView {
    @include fullWidth();

    .item {
        @include fullWidth();
        margin: 15px 10px;

        .activeJobCard {
            @include fullWidth();
            background: #FFFFFF;
            box-shadow: -3px -2px 6px rgba(34, 51, 133, 0.07), 3px 4px 8px rgba(34, 51, 133, 0.07);
            border-radius: 5px;

            .activeJobCardHeader {
                width: 100%;
                padding: 20px 20px;
                padding-bottom: 15px;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;

                .activeLeft {
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 21px;
                    color: #223385;
                    display: flex;
                    flex-direction: column;

                    .postedOn {
                        font-weight: 400;
                        font-size: 13px;
                        line-height: 15px;
                        color: #555555;
                        margin-top: 10px;
                    }
                }

                .activeRight {
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    .activeShareBtn {
                        width: 44px;
                        height: 44px;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: center;
                        background: #FFFFFF;
                        border-radius: 100%;
                        margin-left: 25px;
                        cursor: pointer;
                        box-shadow: -2px -1px 4px rgba(0, 54, 121, 0.1), 2px 3px 6px rgba(0, 54, 121, 0.1);

                        img {
                            width: 20px;
                            height: 18px;
                        }
                    }

                    .MuiInputBase-root {
                        height: 44px;
                        background: #E9FFFF;
                        border-radius: 5px;

                        .MuiSelect-select {
                            font-weight: 500;
                            font-size: 16px;
                            line-height: 19px;
                            text-align: center;
                            color: #32A87D;
                        }

                        .MuiOutlinedInput-notchedOutline {
                            display: none;
                        }

                        .MuiSvgIcon-root {
                            color: #32A87D;
                            margin-top: -2px;
                        }
                    }
                }
            }

            .activeJobCardBody {
                @include fullWidth();
                padding: 15px 20px;
                padding-top: 0;

                .Title {
                    @include fullWidth();
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 16px;
                    color: #002347;
                }

                .acceptRejectCard {
                    @include fullWidth();
                    margin: 20px 0;
                    background: #FFF9F0;
                    border-radius: 5px;

                    .acceptRejectCardSet {
                        // width: 50%;
                        float: left;
                        padding: 24px 28px;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 16px;
                        text-align: center;
                        color: #555555;

                        strong {
                            font-weight: 500;
                            font-size: 20px;
                            line-height: 23px;
                            color: #223385;
                            margin-left: 15px;
                        }

                        &:first-child {
                            border-right: 1px solid #EAEAEA;
                        }
                    }
                }

                .jobDetailsEditSet {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .jobDetailsEditTitle {
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 16px;
                        color: #002347;
                    }

                    .jobDetailsEditIcon {
                        cursor: pointer;

                        img {
                            width: 14px;
                            height: 14px;
                        }
                    }
                }

                .jobDetailsPlanList {
                    @include fullWidth();

                    ul {
                        @include fullWidth();
                        padding-left: 20px;
                        margin: 0;

                        li {
                            @include fullWidth();
                            font-weight: 400;
                            font-size: 13px;
                            line-height: 20px;
                            color: #555555;
                        }
                    }
                }
            }

            .jobDetailsCardFooter {
                padding: 20px 20px;
                border-top: 1px solid #F4F4F4;
                width: 100%;
                padding: 20px 20px;
                border-top: 1px solid #F4F4F4;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .MuiButtonBase-root {
                    width: 48%;
                    float: left;
                    border: 1px solid #E3ECFF;
                    border-radius: 5px;
                    height: 44px;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 20px;
                    color: #223385;
                    background-color: #E3ECFF;
                    text-transform: none;
                    &.viewJobsBtn {
                        background-color: #fff;
                        color: #8E8E8E;
                        border-color: #C1C1C1;
                    }
                }
            }
        }
    }
}

.qualificationSetContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 15px 0;
    @media (max-width: 767px) {
        flex-direction: column;
        align-items: flex-start;
      }

    .qualificationSet {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-weight: 400;
        font-size: 13px;
        line-height: 15px;
        color: #313131;
        margin-right: 30px;
        @media (max-width: 767px) {
            margin-right: 0;
            margin-bottom: 10px;
        }

        &:last-child {
            margin-right: 0;
        }

        img {
            width: 14px;
            height: 14px;
            margin-right: 11px;
        }
    }
}

.JobsActivesTabConatainer {
    @include fullWidth();

    .MuiTabs-root {
        .MuiTab-textColorPrimary {
            padding: 0;
            font-weight: 500;
            font-size: 18px;
            line-height: 21px;
            text-align: center;
            color: #B4B4B4;
            text-transform: none;
            margin-right: 30px;
            align-items: flex-start;
            transition: all ease .3s;

            &.Mui-selected {
                font-weight: 700;
                font-size: 28px;
                line-height: 33px;
                text-align: center;
                color: #223385;
                text-transform: none;
                padding: 0;
                transition: all ease .3s;
            }
        }

        .MuiTabs-indicator,
        .MuiTouchRipple-root {
            display: none;
        }
    }
}

.activeJobListView {
    @include fullWidth();
    padding-top: 30px;
}

.JobCardFilter {
    position: absolute;
    top: -50px;
    right: 0;
    display: flex;
    align-items: center;

    .JobCardSearchBar {
        background: #FFFFFF;
        border: 1px solid #F1F1F1;
        border-radius: 5px;

        .MuiTextField-root {
            .MuiOutlinedInput-notchedOutline {
                display: none;
            }

            .MuiInputBase-formControl {
                height: 44px;
            }

            .MuiFormLabel-root {
                font-weight: 400;
                font-size: 13px;
                line-height: 15px;
                color: #555555;

                &.Mui-focused,
                &.MuiInputLabel-shrink {
                    opacity: 0;
                }
            }
        }

        .MuiButtonBase-root {
            min-width: 54px;
            height: 44px;
            background-color: #F0EAFD;
            color: #223385;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }

    .listGridIconSet {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-left: 60px;

        .listGridIconBtn {
            padding: 12px 22px;
            width: 64px;
            height: 44px;
            background: #F1F4FF;
            cursor: pointer;

            img {
                width: 20px;
                height: 20px;
            }

            &:first-child {
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
            }

            &:last-child {
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
            }

            &.Active {
                background-color: #FFFFFF;
                box-shadow: -3px -2px 6px rgba(34, 51, 133, 0.07), 3px 4px 8px rgba(34, 51, 133, 0.07);

                svg {
                    path {
                        stroke: #223385;
                    }
                }
            }
        }
    }
}

.postedJobsSideBannerContainer {
    @include fullWidth();
    padding: 20px 20px;
    padding-top: 40px;
    background: #FFFFFF;
    box-shadow: -3px -2px 6px rgba(34, 51, 133, 0.05), 3px 4px 8px rgba(34, 51, 133, 0.05);
    border-radius: 5px;
    margin-top: 30px;

    .postedJobsSideBanner {
        @include fullWidth();
    }

    .PostJobBannerTitle {
        @include fullWidth();
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        color: #223385;
        padding: 21px 0;
    }

    .PostJobBannerCheckListContainer {
        @include fullWidth();
    }

    .PostJobBannerCheckListSet {
        width: 100%;
        display: flex;
        align-items: flex-start;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #555555;
        padding-bottom: 15px;

        &:last-child {
            padding-bottom: 0;
        }

        img {
            width: 20px;
            height: 20px;
            margin-right: 10px;
        }
    }
}

.jobPaymentContainer {
    @include fullWidth();

    .jobPaymentBannerImg {
        @include fullWidth();
        padding: 0 50px;
    }

    .payBenefits {
        @include fullWidth();
        padding: 30px 0;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: #555555;
        text-align: center;

        strong {
            font-size: 18px;
            color: #223385;
            padding: 0 2px;
        }
    }

    .sortTitle {
        @include fullWidth();
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        color: #223385;
        text-align: center;
    }

    .jobPaymentCard {
        @include fullWidth();
        padding: 20px 20px;
        border: 1px solid #E0E0E0;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        margin: 20px 0;

        .jobPaymentCardImg {
            width: 80px;
            height: 80px;
            filter: drop-shadow(-2px -1px 4px rgba(0, 54, 121, 0.1)) drop-shadow(3px 4px 8px rgba(0, 54, 121, 0.1));
            border-radius: 5px;
            margin-right: 20px;

            img {
                max-width: 100%;
                height: auto;
            }
        }

        .jobPaymentCardRight {
            width: calc(100% - 100px);

            .jobPaymentCardHeader {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;

                .CardHeaderTitle {
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 21px;
                    color: #002347;
                }

                .jobId {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 16px;
                    color: #555555;
                    margin-right: 20px;

                    span {
                        color: #313131;
                    }
                }

                .amountPerHour {
                    font-weight: 700;
                    font-size: 24px;
                    line-height: 28px;
                    color: #00702D;
                }
            }
            .CardMainTitle {
                @include fullWidth();
                padding: 20PX 0;
                padding-bottom: 10PX;
                font-weight: 600;
                font-size: 16px;
                line-height: 19px;
                color: #223385;
            }
            .CardMainContant {
                @include fullWidth();
                font-weight: 400;
                font-size: 13px;
                line-height: 15px;
                color: #555555;
            }
            .payDateTimeBtnContainer {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: 15px;
                .payDateTimeBtn {
                    display: flex;
                    align-items: center;
                    padding: 16px 20px;
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 21px;
                    color: #223385;
                    box-shadow: -3px -2px 6px rgba(217, 227, 254, 0.3), 3px 4px 8px rgba(217, 227, 254, 0.3);
                    border-radius: 10px;
                    img {
                        width: 24px;
                        height: 24px;
                        margin-right: 7px;
                    }
                }
            }
        }
    }
    .JobPayNowBtn,.JobPayNowBtn:hover {
        width: 100%;
        background: #FFC100;
        border-radius: 5px;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #223385;
        height: 44px;
        padding: 7px 25px;
        box-shadow: none;
        text-transform: none;
        margin-top: 20px;

        .MuiSvgIcon-fontSizeMedium {
            margin-left: 10px;
        }
    }
}

// Job style end

// View Candidates Style Start
.jobAppliedCandidatesContainer {
    @include fullWidth();
    background: #FFFFFF;
    border: 1px solid #E8E8E8;
    border-radius: 5px;
    margin: 30px 0;
    .TotalCandidates {
        @include fullWidth();
        padding: 20px 20px;
        font-weight: 600;
        font-size: 18px;
        line-height: 21px;
        color: #002347;
    }
    .jobAppliedCandidates {
        @include fullWidth();
    }
    .jobAppliedCandidatesSet {
        @include fullWidth();
        padding: 15px 20px;
        border-top: 1px solid #E8E8E8;
        cursor: pointer;
        .jobAppliedCandidatesHeader {
            width: 100%;
            display: flex;
            align-items: center;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            text-align: center;
            color: #313131;
            img {
                width: 30px;
                height: 30px;
                border-radius: 100%;
                margin-right: 12px;
            }
        }
        .jobAppliedSetInfo {
            @include fullWidth();
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            color: #555555;
            padding-top: 10px;
        }
        &.active {
            background-color: #FFF9F0;
        }
    }
}
.candidatesDetailsCard {
    @include fullWidth();
    padding: 20px 20px;
    background: #FFFFFF;
    box-shadow: -3px -2px 6px rgba(34, 51, 133, 0.05), 3px 4px 8px rgba(34, 51, 133, 0.05);
    border-radius: 5px;
    margin: 30px 0;
    .candidatesDetailsCardHeader {
        width: 100%;
        display: flex;
        align-items: center;
        .userImg {
            width: 100px;
            height: 100px;
            border-radius: 5px;
        }
        .CardHeaderNameInfo {
            display: flex;
            flex-direction: column;
            margin-left: 15px;
            .CardHeaderName {
                font-weight: 500;
                font-size: 18px;
                line-height: 21px;
                color: #313131;
            }
            .qualificationSet {
                display: flex;
                flex-direction: row;
                align-items: center;
                font-weight: 400;
                font-size: 14px;
                line-height: 16px;
                color: #555555;
                padding-top: 10px;
                img {
                    width: 14px;
                    height: 14px;
                    margin-right: 11px;
                }
            }
        }
    }
    .candidatesCardInfoSet {
        width: 100%;
        display: flex;
        align-items: flex-start;
        padding-top: 20px;
        .CardInfoLabel {
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            color: #313131;
            min-width: 130px;
        }
        .CardInfoValue {
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: #313131;
        }
    }
}
.skillMatchCard {
    @include fullWidth();
    background: #FFFFFF;
    box-shadow: -3px -2px 6px rgba(34, 51, 133, 0.05), 3px 4px 8px rgba(34, 51, 133, 0.05);
    border-radius: 5px;
    margin: 30px 0;
    .skillMatchCardHeader {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px 20px;
        border-bottom: 1px solid #E8E8E8;
        .skillMatchCardHeaderCard {
            padding: 30px 30px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background: #E9FFFF;
            border-radius: 10px;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color: #55629F;
            strong {
                font-weight: 500;
                font-size: 24px;
                line-height: 28px;
                color: #223385;
                margin-top: 15px;
            }
        }
    }
    .WizardFormContainer {
        @include fullWidth();
        border: none;
        margin: 0;
        padding: 20px 20px;
        textarea {
            @include fullWidth();
            border: solid 1px #E8E8E8 !important;
            padding: 10.5px 14px;
            border-radius: 5px;
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            color: #555555;
            
        }
    }
    .acceptRejectBtnContainer {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        
        .acceptRejectBtn {
            display: flex;
            align-items: center;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            border-radius: 5px;
            text-transform: none;
            margin-top: 30px;
            img {
                width: 24px;
                height: 24px;
                margin-right: 9px;
            }
            &.declineBtn {
                color: #B84246;
                border: solid 1px #B84246;
            }
            &.acceptBtn {
                color: #00702D;
                border: solid 1px #00702D;
            }
        }
    }
}
// View Candidates Style End

// User Profile Style Start
.userProfilePage {
    .WizardFormContainer {
        border: none;
        background: #FFFFFF;
        box-shadow: -3px -2px 6px rgba(34, 51, 133, 0.05), 3px 4px 8px rgba(34, 51, 133, 0.05);
        border-radius: 5px;
    }
}
.userProfileLeftCard {
    @include fullWidth();
    background: #F1F4FF;
    border-radius: 5px;
    padding: 20px 20px;
    position: relative;
    margin: 30px 0;
    .profileCardLogoContainer {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        margin: 30px 0;

        .profileCardLogo {
            width: 170px;
            height: 170px;
            border: solid 10px #EF9152;
            border-radius: 100%;
        }
        .completePercentage {
            padding: 10px 20px;
            font-weight: 600;
            font-size: 14px;
            line-height: 16px;
            text-align: center;
            color: #223385;
            background: #FFF9F0;
            border-radius: 4px;
            margin-top: -33px;
        }
    }
    .ProfileInfoBadge {
        padding: 14px 20px;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #002347;
        background-color: #FFFFFF;
        border-radius: 5px;
        margin-top: 20px;
    }
    .profileLeftBg {
        position: absolute;
        left: 5px;
        top: 90px;
    }
    .profileRightBg {
        position: absolute;
        right: 5px;
    }
}
// User Profile Style End

// Billing Style Start
.employerBillingPage {
    .employerTabel {
        margin: 30px 0;
            .MuiTableContainer-root {
                table {
                    thead {
                        tr {
                            th {
                                background-color: #FFF9F0;
                                padding: 30px 16px;
                            }
                        }
                    }
                }
            }
    }
    .postedJobsSideBannerContainer {
        .PostJobBannerTitle {
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            text-align: center;
            color: #002347;
        }
        .PostJobBannerDays {
            @include fullWidth();
            padding-bottom: 20px;
            font-weight: 700;
            font-size: 26px;
            line-height: 30px;
            text-align: center;
            color: #00702D;
        }
        .subscribeBtn {
            @include fullWidth();
            padding: 10px 10px;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #223385;
            background: #FFC100;
            border-radius: 5px;
            text-transform: none;
            margin-bottom: 30px;
        }
    }
}
// Billing Style End

.emptyDashBoardPage {
    &.applicationStatusPage {
        .PostJobBannerCheckListSet{
            justify-content: flex-start !important;
        }
    }
}
.WizardFormContainer {
    .post-job-textarea{
        textarea{
            border: none !important;
        }

    }

}
