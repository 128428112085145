.Dashboard-card-screen {
	width: 100%;
	float: left;
}

.Page-inner-container {
	background: #fff;
}

.Dashboard-card-screen .card {
	font-size: 14px;
	margin: 15px 0;
	position: relative;
}

.Dashboard-card-screen .card .card-dropdown {
	position: absolute;
	top: 8px;
	right: 14px;
}

.Dashboard-card-screen .card .ant-dropdown-trigger {
	font-size: 20px;
}

.card-dropdown-menu .ant-dropdown-menu-item a {
	text-decoration: none;
}

.headName {
	color: #223385;
	text-align: center;
	font-family: Roboto;
	font-size: 28px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}

.totalCard {
	border-radius: 10px;
	background: #F1F4FF;
}

.activeCard {
	border-radius: 10px;
	background: #e8ffff;
}

.pinkCard {
	border-radius: 10px;
	background: #fcdeea;
}

.sandalCard {
	border-radius: 10px;
	background: #fdf3e5;
}

.blueCard {
	border-radius: 10px;
	background: #E7F3FF;
}

.cardShadow {
	border-radius: 5px !important;
	background: #FFF;
	box-shadow: 3px 4px 8px 0px rgba(34, 51, 133, 0.10), -3px -2px 6px 0px rgba(34, 51, 133, 0.10) !important;
}
