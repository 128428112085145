@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
body {
  padding: 0;
  margin: 0;
  background-color: #f5f8fa;
  font-family: 'Poppins', sans-serif;
}
a {
    text-decoration: none !important;
}
button {
    outline: none !important;
}
.aside-dark .hover-scroll-overlay-y:hover {
    scrollbar-color: #41416e transparent;
}
.aside-fixed .wrapper {
    transition: padding-left .3s ease;
    padding-left: 265px;
}
.aside-dark .hover-scroll-overlay-y {
    --scrollbar-space: 0.4rem;
    scrollbar-color: #3b3b64 transparent;
}
.hover-scroll-overlay-y:hover {
    overflow-y: overlay;
}
div:hover, main:hover, ol:hover, pre:hover, span:hover, ul:hover {
    scrollbar-color: #e9edf1 transparent;
}
.hover-scroll-overlay-y {
    overflow-y: hidden;
    position: relative;
    --scrollbar-space: 0.5rem
}

.hover-scroll-overlay-y::-webkit-scrollbar {
    width: calc(.4rem + var(--scrollbar-space))
}

.hover-scroll-overlay-y::-webkit-scrollbar-thumb {
    background-clip: content-box;
    border-right: var(--scrollbar-space) solid transparent
}

.hover-scroll-overlay-y:hover {
    overflow-y: overlay
}

@-moz-document url-prefix() {
    .hover-scroll-overlay-y {
        overflow-y: scroll;
        position: relative
    }
}

.scroll-ps {
    padding-left: .4rem !important
}

.scroll-ms {
    margin-left: .4rem !important
}

.scroll-pe {
    padding-right: .4rem !important
}

.scroll-me {
    margin-right: .4rem !important
}

.scroll-px {
    padding-left: .4rem !important;
    padding-right: .4rem !important
}

.scroll-mx {
    margin-left: .4rem !important;
    margin-right: .4rem !important
}
.aside-dark .hover-scroll-overlay-y {
    --scrollbar-space: 0.4rem;
    scrollbar-color: #3b3b64 transparent
}

.aside-dark .hover-scroll-overlay-y::-webkit-scrollbar-thumb {
    background-color: #3b3b64
}

.aside-dark .hover-scroll-overlay-y:hover {
    scrollbar-color: #41416e transparent
}

.aside-dark .hover-scroll-overlay-y:hover::-webkit-scrollbar-thumb {
    background-color: #41416e
}
.hover-scroll-overlay-y {
    overflow-y: hidden;
    position: relative;
    --scrollbar-space: 0.5rem;
}
.Page-inner-container {
  width: 100%;
  float: left;
  padding: 16px 30px;
  min-height: calc(100vh - 123px);
}
.card {
    border: 0;
    box-shadow: 0 0 20px 0 rgb(76 87 125 / 2%);
}
.card.card-xl-stretch {
    height: calc(100% - var(--bs-gutter-y));
}
.card .card-body {
        padding: 1rem 1.25rem;
}
.py-lg-13 {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
}
.text-dark {
    color: #181c32 !important;
}
.text-muted {
    color: #a1a5b7 !important;
}
.h-100px {
    height: 100px !important;
}
.page-layout.wrapper {
    transition: padding-left .3s ease;
    padding-left: 265px;
}

/* 21-01-2022 */
@media (max-width:991.98px) {
    .page-layout.wrapper {
     padding-left: 0;
    }
    .header-fixed .header {
     left: 0;
    }
    .header .header-menu {
        display: flex;
 }
 .header-menu .Header-menu-Icon {
     display: none;
 }
 .header-menu .Header-menu-Icon {
    display: block;
    left: 0;
    margin-top: -6px;
    width: inherit;
}
.aside {
    display: block;
    left: -265px;
}
.menu-close .aside {
    left: 0;
}
.menu-item .menu-link {
    padding: 7px 10px;
    font-size: 13px;
}
.aside .aside-logo {
    display: block;
}
.aside .aside-logo {
    display: flex;
}
 }


.info-window .info-title {
	padding: 10px !important;
}

.gm-style-iw {
	padding: 15px !important;
}

.gm-ui-hover-effect {
	top: -4px !important;
	right: 0px !important;
	outline: none !important;
	border: none !important;
}

.gm-style-iw-d {
    overflow: hidden !important;
}

 

.jobs-list .ant-table-container table tr th:nth-child(3) {
    width: 250px;
  }
  
  .jobs-list .ant-table-container .ant-table-tbody tr td:nth-child(3) {
    width: 250px;
  }
  
  .jobs-list .ant-table-container .ant-table-tbody tr td .react-read-more-read-less {
    cursor: pointer;
    font-size: 13px;
    color: #000;
    text-decoration: underline;
    font-weight: 600;
  }
  
  .jobs-list .css-1nrlq1o-MuiFormControl-root .css-dmmspl-MuiFormGroup-root {
    display: flex;
    flex-direction: row;
  }
  
  .jobs-list .css-10nakn3-MuiModal-root-MuiPopover-root-MuiMenu-root .css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.04);
  }

  .manger-list-table .MuiTable-root .MuiButtonBase-root,
  .manger-list-table .css-sghohy-MuiButtonBase-root-MuiButton-root {
    background-color: #350087;
    color: #fff;
    border-color: #350087;
    outline: none !important;
}
.manger-list-table .css-1wvnaxz-MuiTableCell-root.MuiTableCell-head {
    position: relative;
    color: rgba(0, 0, 0, 0.88);
    font-weight: 600;
    text-align: start;
    background: #fafafa;
    border-bottom: 1px solid #f0f0f0;
}

.manger-list-table .css-1cjjkw7-MuiPaper-root-MuiTableContainer-root {
    box-shadow: none;
}
.css-1e6y48t-MuiButtonBase-root-MuiButton-root {
    font-size: 14px !important;
}