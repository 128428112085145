.template-list-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.full-page-container {
  width: 100%;
  float: left;
  padding: 15px;
  background-color: #fff;
  border-radius: 4px;
  min-height: calc(100vh - 95px);
}
.page-title {
  width: 100%;
  float: left;
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 15px;
  color: #1c1527;
}

.coach-full-conatiner {
  width: 100%;
  float: left;
  align-items: center;
  padding: 0;
}

.form-btn-4 {
  background-color: #38a300 !important;
  border-radius: 22px !important;
  width: 100px !important;
  height: 44px !important;
  color: #fff !important;
}

.form-btn-4-back {
  border-radius: 22px !important;
  width: 100px !important;
  height: 44px !important;
  color: #fff !important;
  background-color: #a7a7a7 !important;
}

.unsaved-btn {
  background-color: #e85f25;
  border-radius: 22px !important;
  width: 23% !important;
  height: 44px !important;
  color: #fff !important;
}

.form-btn-4-schedule {
  width: 52% !important;
  background-color: #e85f25;
  border-radius: 22px !important;
  height: 44px !important;
  color: #fff !important;
}

.template-list-table-con {
  border: solid 1px #e85f2f;
  border-radius: 37px;
  background-color: #e85f2f;
}

.template-list-table {
  width: 100%;
  float: left;
  background-color: #fff;
  padding: 0;
  border-radius: 0;
  margin-top: 0;
}

.template-list-table .ant-table-thead>tr>th {
  background-color: #e9e9e9;
  border-bottom: none;
}

.template-list-table .ant-table-row {
  background-color: #e9e9e9;
  border: none;
}

.template-list-table .ant-table-row td {
  background-color: #e9e9e9;
  border-bottom: 1px solid #d7d7d7;
}

.template-list-table .ant-table-tbody>tr.ant-table-row:hover>td,
.ant-table-tbody>tr>td.ant-table-cell-row-hover {
  background-color: #e9e9e9;
}

.template-list-table .ant-table-thead>tr>th {
  font-weight: 800;
  font-size: 15px;
}

.template-list-table .ant-table-tbody {
  background-color: #e9e9e9;
}

.template-list-table .ant-table-tbody:hover {
  background-color: #e9e9e9;
}

.agent-list-action-btns .anticon svg {
  margin-top: -4px;
  font-size: 16px;
  color: #a7a7a7;
}

.form-btn-4 .anticon svg {
  margin-top: -4px;
}

.form-btn-4-schedule .anticon svg {
  margin-top: -4px;
}

.template-list-table .ant-spin-dot-item {
  background-color: #e85f25;
}

.agreement-clauses-data {
  border: 1px solid lightgrey;
  border-radius: 10px;
  margin-top: 23px;
}

.Google-Map-placeholder {
  width: 100%;
  height: 191px;
  background-size: cover !important;
  background-position: center center !important;
  background-repeat: no-repeat !important;
  animation: fading 1.5s infinite;
  border-radius: 15px;
}

.Google-Map-Container {
  width: 100%;
  /* height: 304px; */
  border-radius: 5px;
  /* overflow: hidden; */
  /* margin-bottom: 30px; */
}

.view-template-photo .ant-image {
  margin-right: 11px;
}

.success-save-modal {
  width: 770px !important;
}

.success-save-modal .ant-modal-body {
  padding: 53px !important;
}

.manage-full-conatiner {
  margin-top: 43px;
  width: 100%;
  float: left;
}

.manage-full-conatiner .ant-tabs-tab {
  font-size: 16px;
  font-weight: 700;
}

.manage-full-conatiner .ant-tabs-nav-list {
  width: 70%;
}

.manage-full-conatiner .ant-tabs-top>.ant-tabs-nav::before,
.ant-tabs-top>div>.ant-tabs-nav::before {
  border-bottom: none;
}

.schedule-template-select {
  border-radius: 20px !important;
  height: 43px !important;
  width: 58%;
}

.schedule-template-select .ant-select-selector {
  border-radius: 20px !important;
  height: 43px !important;
  width: 58%;
}

.unsaved-btn-cancel {
  background-color: #a7a7a7;
  border-radius: 22px !important;
  width: 23% !important;
  height: 44px !important;
  color: #fff !important;
}

.preview-selected-date-name {
  margin-left: 14px;
  margin-top: 4px;
  display: flex;
  align-items: center;
}

.refund-modal-input {
  height: 51px !important;
  width: 55% !important;
  border-radius: 28px !important;
}

.preview-selected-date-refund {
  border: solid 1px lightgrey;
  height: 47px;
  border-radius: 25px;
  margin-bottom: 2.5%;
  margin-top: 2%;
  width: 55%;
  background-color: #a7a7a7;
}

.preview-selected-date-name-refund {
  margin-left: 14px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 18px;
  font-weight: 700;
}

.unsaved-btn-cancel-refund {
  background-color: #a7a7a7;
  border-radius: 22px !important;
  width: 23% !important;
  height: 44px !important;
  /* color: #fff !important; */
}

.form-btn-4-csv {
  background-color: #e85f25 !important;
  border-radius: 22px !important;
  height: 44px !important;
  color: #fff !important;
}

.template-modals .ant-select-selection-item {
  margin-top: 6px;
}

.table-paid-status {
  display: inline-block;
  padding: 3px 15px;
  background-color: #38a300 !important;
  border-radius: 25px;
  color: #fff;
}

.table-status-cancelled {
  display: inline-block;
  padding: 3px 15px;
  background-color: #e22730 !important;
  border-radius: 25px;
  color: #fff;
}

.table-status-completed {
  display: inline-block;
  padding: 3px 15px;
  background-color:blueviolet !important;
  border-radius: 25px;
  color: #fff;
}


.user-title-style {
  font-size: 17px;
  font-weight: 900;
  color: #5f5f5f;
  margin-bottom: 2%;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border: 1px solid #df602d !important;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background: #df602d !important;
}

.ant-picker-dropdown .ant-btn-primary {
  border-color: #df602d !important;
  background: #df602d !important;
}

.ant-picker-time-panel-column>li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: #e05d1b !important;
}

.prorated-pricing {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.rmdp-day.rmdp-today span {
  background-color: #f8b88b !important;
}

.rmdp-day.rmdp-selected span:not(.highlight) {
  background-color: #e05d1b !important;
  box-shadow: 0 0 3px #8798ad;
  color: #fff;
}

.rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover {
  background-color: #f8b88b !important;
  color: #fff;
}

.rmdp-panel-body li,
.rmdp-panel-body li.bg-blue {
  background-color: #e05d1b !important;
}

.rmdp-action-button {
  border-radius: unset;
  color: #e05d1b !important;
  float: right;
  font-weight: 700;
  margin: 15px 10px 15px 0;
}

.rmdp-week-day {
  color: #e05d1b !important;
  cursor: default;
  font-size: 13px;
  font-weight: 500;
}

.rmdp-arrow-container:hover {
  background-color: #e05d1b !important;
  box-shadow: 0 0 3px #8798ad;
}

.set-schedule-inner-container .rmdp-arrow {
  border: solid #e05d1b;
  border-top-width: medium;
  border-right-width: medium;
  border-bottom-width: medium;
  border-left-width: medium;
  border-width: 0 2px 2px 0;
  display: inline-block;
  height: 3px;
  margin-top: 5px;
  padding: 2px;
  width: 3px;
}

.CatagoriViewListleft {
  width: 30%;
  justify-content: space-between;
}

.catagoriViewList {
  width: 30%;
}

.btn {
  background-color: green;
}

.form-input.ant-input-affix-wrapper {
  border: solid 1px #000;
  border-radius: 5px;
  height: auto;
  padding: 5px 12px;
  font-size: 15px;
}

.form-input.ant-input-affix-wrapper>.ant-input {
  font-size: 14px;
}

.name-inner-conatiner-input1 {
  display: flex;
  flex-direction: column;
  border: 1px solid #d9d9d9;
  border-radius: 11px;
  margin-bottom: 26px;
}

.coach-info-conatiner-first {
  width: 100%;
  align-items: center;
}

.form-btn-inner-container-4 .ant-form-item-control-input-content {
  display: flex;
  /* justify-content: start; */
  flex-direction: row;
}

.form-btn-inner-container-4 .form-btn-4 {
  background-color: #38a300 !important;
  border-radius: 22px;
  width: 14%;
  height: 44px;
  color: #fff;
}

.form-btn-inner-container-4 .form-btn-back {
  background-color: #38a300 !important;
  border-radius: 22px;
  width: 14%;
  height: 44px;
  color: #fff;
}

.title-style {
  font-weight: 900;
  color: #ababab;
  margin-bottom: 12px;
  text-transform: uppercase;
}

.main-menu-page {
  width: 100%;
  float: left;
}

.page-inner-container {
  width: 100%;
  float: left;
  padding: 15px 15px;
  background-color: #fff;
  border-radius: 5px;
  min-height: calc(100vh - 103px);
}

.page-inner-container .ant-input.form-input {
  border: solid 1px #000;
  border-radius: 5px;
  height: auto;
  padding: 5px 12px;
  font-size: 15px;
}

.form-input.ant-select:not(.ant-select-customize-input) .ant-select-selector,
.form-input.ant-input-textarea .ant-input {
  border: solid 1px #000;
  border-radius: 5px;
  height: auto;
  /* padding: 5px 12px; */
  font-size: 14px;
}

.form-input {
  width: 800px;
}
.searchContainer {
  display: flex;
  justify-content: end;
  padding: 0 40px 0 0px;
}
.tableContainer{
  position:fixed;
  /* width:100%; */
  /* left:0; */
  /* top:0; */
  /* right: 0; */
  z-index: 10
}
.form-input{
  border: solid 1px #000;
  border-radius: 5px;
  height: auto;
  padding: 5px 12px;
  font-size: 15px;
}
.form-control {
  width: 100%;
  margin-top: 27px;
  margin-bottom: 30px;
}
.form-menu{
  width: 100%;
}

.MuiOutlinedInput-notchedOutline {
  border: none;
  border-right: 1px solid #E2E2E2;
}
/* .MuiOutlinedInput-root {
  border-radius: 0;
  width: 50px;
} */
.MuiOtpInput-Box {
  gap: 0;
}
.uploadedImage {
      width: 100px;
      height: 100px;
      margin-top: 10px;
      border-radius: 5px;
 
}

.addMoreItemBtn{
  width: 100%;
  float: left;
padding-left: 800px;
  justify-content: right;
}

.template-list-title-cont{
  display: flex;
  justify-content: space-between;
}

.btn-create{
    background: #223385;
    color: #ffff;
    border: 1px solid #223385;
    border-radius: 5px;
    padding: 8px;
    cursor: pointer;
}