@mixin fullWidth {
    width: 100%;
    float: left;
}

.bb-none {
    border-bottom: none !important;
}

.tableLoader {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 200px;
}

.noData {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 250px;

    svg {
        width: 200px;
    }
}

.employerTabel {

    .tabelContainer {
        position: relative;
        // max-height: 400px;
        max-height: 65vh;

        &.maxHeight55 {
            max-height: 55vh;
        }

        &::-webkit-scrollbar {
            width: 10px;
            height: 10px;
        }

        &::-webkit-scrollbar-thumb {
            background: rgba(95, 103, 109, .2);
            border-radius: 5px;
            box-shadow: inset 0 0 0 1px #fff;
        }

        &::-webkit-scrollbar-track {
            background-color: rgba(199, 204, 209, .4);
            border-radius: 5px;
        }
    }

    .MuiTable-root {

        .MuiTableHead-root {
            .MuiTableCell-head {
                position: sticky;
                top: 0;
                z-index: 2;
            }
        }
    }

}

.WizardFormContainer {
    .css-13bvbr1-MuiStack-root {
        width: 100%;
    }
}

.SiginLoader {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99999;
}

.card-pagination {
    @include fullWidth();

    .MuiPagination-ul {
        justify-content: flex-end;
    }
}

.exploreCardContainer .exploreCard .exploreCardBody .MuiTypography-root {
    min-height: 45px;
}

.ifsc-code-container {
    @include fullWidth();
    position: relative;

    .MuiButton-text.MuiButton-textPrimary {
        color: #2B3E97;
        font-size: 13px;
        text-transform: none;
        text-decoration: underline;
        float: right;
        padding: 0;
        padding-bottom: 5px;
        background-color: transparent;
    }
}

.myJobsCard {
    .cardInfoFooterPaymentSet {
        @include fullWidth();
        padding: 20px 0;
        margin-top: 30px;
        border-top: solid 1px #E1E1E1;

        p {
            font-size: 13px;
            color: #313131;
            margin: 0;
            margin-bottom: 26px;
        }

        .paymentBtn {
            padding: 18px 70px;
            font-size: 16px;
            font-weight: 600;
            line-height: 19px;
            color: #fff;
            background-color: #00702D;
        }
    }
}

.employerDashboardLayout {
    .EmployeerHeaderContainer {
        background-color: #FAF8FF;
        @media (max-width: 991px) {
            padding: 0 15px;
          }
    }
}

.profile-edit-modal {
    .cancel-btn {
        background-color: #C8C8C8;
        color: #838384;
        text-transform: none;
        padding: 6px 20px;
        margin-right: 15px;
    }

    .update-btn {
        background-color: #FBBC05;
        color: #223385;
        text-transform: none;
        padding: 6px 20px;
    }

    .scrollableContainer {
        height: auto;
    }

    &.PartTimerRegistrationPage {
        float: none;
        width: auto;
        padding: 0;
    }

    .registrationWizard {
        margin-bottom: 20px;
        box-shadow: none !important;
    }

    .WizardFormContainer {
        padding: 0;
        box-shadow: none !important;
    }

    .ant-modal-content {
        margin-bottom: 30px;
    }
}

.errorMsg {
    color: #d32f2f;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
    letter-spacing: 0.03333em;
    text-align: left;
    margin-top: 3px;
    margin-right: 14px;
    margin-bottom: 0;
    margin-left: 14px;
}

.WizardFormContainer {
    .css-1qes3pk-MuiStack-root {
        width: 100%;
    }
}

.exploreCardContainer {
    .exploreCard {
        .exploreCardFooter {
            .exploreCardFooterAmount {
                white-space: nowrap;
            }

            .exploreCardFooterLink {
                white-space: nowrap;
            }
        }
    }
}

.userProfilePage .profileMainCard .profileEditCard .infoSet {
    word-break: break-all;
}

.MuiFormControl-root {
    .MuiInputBase-root {
        &.home-location-field {
            .MuiSelect-icon {
                display: none;
            }

            .MuiSelect-select {
                position: relative;

                &:after {
                    content: '';
                    width: 14px;
                    height: 14px;
                    position: absolute;
                    top: 20px;
                    right: 18px;
                    background-image: url(./assets/img/location-pin.png);
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-size: cover;
                }

                &[aria-expanded~=true] {
                    &:after {
                        transform: rotate(0deg);
                    }
                }
            }
        }
    }
}

.skillMatchCard {
    &.boxNone {
        box-shadow: none;
        margin: 0;

        .WizardFormContainer {
            padding-bottom: 0;

            .acceptRejectBtnContainer {
                justify-content: flex-end;

                .acceptRejectBtn {
                    &.MuiButton-text {
                        margin-top: 0;
                        margin: 5px 0;
                        margin-left: 15px;
                    }
                }
            }
        }
    }
}
.jobAppliedCandidatesContainer {
    .jobAppliedCandidatesSet {
      .jobAppliedCandidatesHeader {
        text-align: left !important;
      }
    }
  }
  

.activeJobGridView {
    .item {
      .activeJobCard {
        .activeJobCardBody {
          .jobDetailsPlanList {
            ul {
              li {
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                height: 60px;
              }
            }
          }
        }
      }
    }
  }
  .exploreCardContainer .exploreCard {
    text-decoration: none !important;
    outline: none !important;
}
.jobPaymentContainer .jobPaymentCard .jobPaymentCardRight .jobPaymentCardHeader {
    align-items: flex-start !important;
}
.jobPaymentContainer .jobPaymentCard .jobPaymentCardRight .jobPaymentCardHeader .jobIdRight {
    white-space: nowrap;
}
.SinginFormPage .SinginFormLeft {
    position: relative;
}
.SinginFormPage {
    .SinginFormLeft {
        .SinginFormBgImg {
            &.BannerSVG {
                margin-top: -70px;
                margin-bottom: -150px;
            }
        }
    }
}
.filterInputContainer {
    .MuiPaper-elevation {
        .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
                border: none;
                box-shadow: none;
        }
        .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root {
            display: none;
        }
        .MuiSelect-select:after {
            display: none;
        }
        .MuiInputBase-input {
            padding: 0;
            padding-right: 15px;
        }
        .MuiFormControl-root {
            .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
                font-size: 14px;
                margin-top: -15px;
                left: -15px;
            }
        }
    }
}

.SinginFormLeft {
    .loginBackBtn {
        position: absolute;
        top: 30px;
        left: 40px;
        color: #313131;
        font-family: Roboto;
        font-size: 14px;
        font-weight: 400;
        text-transform: none;
        background-color: #fff;
        z-index: 10;
        svg {
            margin-right: 10px;
        }
    }
}

.SinginFormPage {
    .SinginFormLeft {
      .SinginFormBgImg {
        @media (max-width: 991px) {
          display: none;
        }
      }
      
      .SinginFormCarouselContainer {
        @media (max-width: 991px) {
          display: none;
        }
      }
      .loginBackBtn {
        @media (max-width: 991px) {
            top: 15px;
            left: 15px;
          }
      }
    }
    .SinginFormRight {
        @media (max-width: 991px) {
            padding: 70px 15px !important;
            position: relative;
            z-index: 2;
          }
    }
  }
  .jobNodata {
    min-height: 150px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }



  .hideWeb {
    @media (min-width: 992px) {
        display: none !important;
    }
    
    @media (max-width: 991px) {
        display: block;
    }
  }

  .registrationWizard {
    .WizardFormContainer {
  .css-4jnixx-MuiStack-root {
    overflow: visible;
  }
  input:-internal-autofill-selected {
    background-color: #fff !important;
  }
  }
  }

  .userProfilePage {
    .profileMainCard {
      .profileEditCard {
        .userInfoContainer {
          .userImg {
            object-fit: cover;
          }
        }
      }
    }
}
.ComplaintViewPage {
    .ComplaintViewTitle {
        @include fullWidth();
        color: #223385;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        padding-bottom: 15px;
    }
    .ComplaintViewCard {
        width: 100%;
        height: 100%;
        display: block;
        box-shadow: 3px 4px 8px 0px rgba(34, 51, 133, 0.10), -3px -2px 6px 0px rgba(34, 51, 133, 0.10);
        padding: 15px 15px;
        border-radius: 5px;
        margin-top: 50px;
        position: relative;
        .ComplaintViewCardSet {
            width: 100%;
            display: flex;
            padding: 6px 0;
            .ViewSetTitle {
                font-size: 14px;
                font-weight: 600;
                min-width: 200px;
                float: left;
                position: relative;
                &:after {
                    content: ':';
                    position: absolute;
                    right: 0;
                    font-weight: 600;
                }
            }
            .ViewSetName {
                font-size: 14px;
                float: left;
                padding-left: 10px;
            }
        }
        .ant-btn {
            &.resolveBtn,&.resolveBtn:active,&.resolveBtn:focus,&.resolveBtn:hover {
                position: absolute;
                bottom: 20px;
                left: 15px;
                padding: 6px 30px;
                height: auto;
                background-color: #350087;
                color: #fff;
                border-color: #350087;
                outline: none !important;
            }
        }
    }
}

.WizardFormContainer {
.MuiInputBase-root {
    &.MuiInputBase-formControl{
        &.form-control {
            margin: 0;
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    height: 53.13px;
    box-shadow: none;
    .MuiInputBase-input {
        font-size: 14px;
        line-height: 16px;
        color: #000;
        padding: 9px 0;
        line-height: 24px;
    }
        }
    }
}
.css-4jnixx-MuiStack-root {
    overflow: visible;
    padding-top: 0;
}
}
.Page-inner-container {
    .ant-table-container {
        .ant-table-body {
            overflow-y: auto !important;
        }
    }
}
.form-control {
    &.css-1u3bzj6-MuiFormControl-root-MuiTextField-root{
    height: 56px;
}
&.css-1cpjabz-MuiFormControl-root-MuiTextField-root {
    margin-top: 0 !important;
}
.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
    font-size: 14px;
    line-height: 25px;
}
}
.MuiFormControl-root {
    .form-control {
        margin: 0;
    }
}
.searchContainer {
    margin-bottom: 15px;
}
.dashboardPageContainer {
    background-color: #fff;
}
.form-control {
    &.css-1h51icj-MuiAutocomplete-root{
        margin: 0;
    }
&.css-1h51icj-MuiAutocomplete-root {
    padding: 0;
    border: none;
    height: 53.13px;
}
}
textarea{
    &.form-control {
        margin: 0 !important;
    }
}
.SinginFormRight {
    .form-control {
        height: 53.13px;
    }
    .SinginFormBtn {
        width: 100%;
        margin: 0;
        outline: none !important;
    }
}

.menu-close .template-list-title {
    transition: all ease .3s;
    margin-left: 50px;
}
.template-list-title {
    transition: all ease .3s;
    margin-left: 0;
}

.MenuContainer .MuiPaper-elevation{
    box-shadow: 0px 6px 11px rgba(0, 0, 0, 0.031);
}
.MuiInputBase-input .MuiOutlinedInput-input .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input{

    padding: 18px 14px;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #555555;
  background-color: #fff;
}
.export-excell-btn:hover{
    background-color: #02265b !important;
}

